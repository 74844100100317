.App {
  position: relative;
  overflow-x: hidden;
}

.bg {
  z-index: -10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(140deg, #00fff3, #ffa7ff, #babaff, #80ff78, #38acff, #ff60d5);
  // background-size: 1200%;
}

.bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #54545487;
}

.screen__bg-scene {
  z-index: -10;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-x: clip;
  /* filter: blur(3px);
  -webkit-filter: blur(3px); */
}

#screen-1 {
  .bg-scene__composition {
    position: absolute;
    top: calc(var(--screen-height, 100vh) - 365px);
    left: 0;
    right: 0;
  }

  .composition__item {
    width: auto;
    margin: 0 -135px;
    opacity: 0;

    &--onload {
      opacity: 1;
      transition: opacity .5s ease-in;
    }

    &:first-of-type {
      position: absolute;
      top: 110px;
      left: 110px;
      height: 155px;

      transform: rotateY(180deg) rotateZ(-20deg);
      -webkit-transform: rotateY(180deg) rotateZ(-20deg);

      filter: blur(8px) brightness(.7) grayscale(.25) contrast(.65);
      -webkit-filter: blur(4px) brightness(.7) grayscale(.25) contrast(.65);

      animation: composition-1__item-1 ease-in-out 6.5s infinite;
      -webkit-animation: composition-1__item-1 ease-in-out 6.5s infinite;
    }

    &:last-of-type {
      position: absolute;
      top: 155px;
      right: 80px;
      height: 170px;
      
      transform: rotateZ(-13deg);
      -webkit-transform: rotateZ(-13deg);

      filter: blur(5px) brightness(.8) grayscale(.25) contrast(.65);
      -webkit-filter: blur(3px) brightness(.8) grayscale(.25) contrast(.65);

      animation: composition-1__item-2 ease-in-out 8.5s infinite;
      -webkit-animation: composition-1__item-2 ease-in-out 8.5s infinite;
    }

    @keyframes composition-1__item-1 {
      0% {
        transform: translateY(15px) rotateY(180deg) rotateZ(-15deg);
        -webkit-transform: translateY(15px) rotateY(180deg) rotateZ(-15deg);
      }
      50% {
        transform: translateY(-15px) rotateY(180deg) rotateZ(-20deg);
        -webkit-transform: translateY(-15px) rotateY(180deg) rotateZ(-18deg);      
      }
      100% {
        transform: translateY(15px) rotateY(180deg) rotateZ(-15deg);
        -webkit-transform: translateY(15px) rotateY(180deg) rotateZ(-15deg);
      }
    }
    
    @keyframes composition-1__item-2 {
      0% {
        transform: translateY(15px) rotateZ(-13deg);
        -webkit-transform: translateY(15px) rotateZ(-13deg);
      }
      50% {
        transform: translateY(-15px) rotateZ(-10deg);
        -webkit-transform: translateY(-15px) rotateZ(-10deg);
      }
      100% {
        transform: translateY(15px) rotateZ(-13deg);
        -webkit-transform: translateY(15px) rotateZ(-13deg);
      }
    }
  }
}

#screen-2 {
  min-height: 100vh;

  .bg-scene__composition {
    position: absolute;
    top: calc(var(--screen-height, 100vh) - 400px);
    left: 0;
    right: 0;
  }

  .composition__item {
    width: auto;
    margin: 0;
    opacity: 0;

    &--onload {
      opacity: 1;
      transition: opacity .5s ease-in;
    }

    &:first-of-type {
      position: absolute;
      top: 35px;
      left: -1vw;
      height: auto;
      width: 50vw;

      transform: rotateZ(7deg);
      -webkit-transform: rotateZ(7deg);

      filter: blur(3px) brightness(.85) grayscale(.1) contrast(.7);
      -webkit-filter: blur(3px) brightness(.85) grayscale(.1) contrast(.7);

      animation: composition-2__item-1 ease-in-out 8s infinite;
      -webkit-animation: composition-2__item-1 ease-in-out 8s infinite;
    }

    &:last-of-type {
      z-index: -1;
      position: absolute;
      top: 0;
      right: -12vw;
      height: auto;
      width: 63vw;
      
      transform: rotateZ(70deg);
      -webkit-transform: rotateZ(70deg);

      filter: blur(5px) brightness(1) grayscale(.25) contrast(.7);
      -webkit-filter: blur(5px) brightness(1) grayscale(.25) contrast(.7);

      animation: composition-2__item-2 ease-in-out 11s infinite;
      -webkit-animation: composition-2__item-2 ease-in-out 11s infinite;
    }

    @keyframes composition-2__item-1 {
      0% {
        transform: translateY(10px) rotateZ(4deg);
        -webkit-transform: translateY(10px) rotateZ(4deg);
      }
      50% {
        transform: translateY(-10px) translateX(-3px) rotateZ(7deg);
        -webkit-transform: translateY(-10px) translateX(-3px) rotateZ(7deg);
      }
      100% {
        transform: translateY(10px) rotateZ(4deg);
        -webkit-transform: translateY(10px) rotateZ(4deg);
      }
     }
    
    @keyframes composition-2__item-2 {
      0% {
        transform: translateY(10px) translateX(0) rotateZ(71deg);
        -webkit-transform: translateY(10px) translateX(0) rotateZ(71deg);
      }
      50% {
        transform: translateY(-5px) translateX(4px) rotateZ(68deg);
        -webkit-transform: translateY(-5px) translateX(4px) rotateZ(68deg);
      }
      100% {
        transform: translateY(10px) translateX(0) rotateZ(71deg);
        -webkit-transform: translateY(10px) translateX(0) rotateZ(71deg);
      }
     }

  }
}

.screen {
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 3.25rem 1.25rem 1.25rem;
}

.down-pointer {
  font-size: 36px;
  text-align: center;
  animation: float-4 1.5s ease-out infinite;
}

.slider {
  margin: 0 -1.25rem;
  overflow: hidden;
}

.products-slider, .promo-slider {
  margin-top: .5rem;
}

#screen-1 {
  min-height: var(--screen-height, 100vh);

  em {
    font-style: normal;
  }

  abbr {
    text-decoration: none;
  }

  .lazy-load-image__wrapper {
    margin: 0 auto;
  }

  .screen__emoji-img {
    width: auto;
    height: 12.75rem;
    max-height: 262px;
    margin: 1.5rem auto 0;
    transform: translate(2px, 5px) rotate(3deg);
    opacity: 0;
    /* filter: drop-shadow(-1px 10px 45px rgba(255, 255, 255, 0.5)); */
    /* -webkit-filter: drop-shadow(-1px 10px 45px rgba(255, 255, 255, 0.5)); */
    
    &--onload {
      opacity: 1;
      transition: opacity .3s ease-in;
      animation: waving 1.5s ease-in .75s;
      -webkit-animation: waving 1.5s ease-in .75s;
    }
  }
}

#screen-3, #screen-5 {
  h1 {
    font-size: 31.2px;
    letter-spacing: -0.1px;
    word-spacing: -0.2px;
  }
}

#screen-6 {
  h1 {
    font-size: 39px;
  }
}

.character-replica-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -1.25rem;

  &__text {
    width: 66%;
    word-break: break-word;
    transform: rotateZ(352deg);
    text-shadow: 1px -2px #000000bf;
  }
  
  &__img {
    height: 100%;
    width: 75%;
    margin-left: 20%;
    opacity: 0;

    &--onload {
      opacity: 1;
      transition: opacity .5s ease-in;
    }
  }
}

@keyframes blink {
  25% {
    transform: scale(.95) translate(-6px, -6px);
    -webkit-transform: scale(.95) translate(-6px, -6px);
  }
  35% {
    opacity: .66;
  }
  70% {
    opacity: 0;
  }
  75% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes waving {
  0% {
    transform: translate(2px, 5px) rotate(3deg) scale(1);
    -webkit-transform: translate(2px, 5px) rotate(3deg) scale(1);
  }
  25% {
    transform: translateY(-3px) scale(1.05);
    -webkit-transform: translateY(-3px) scale(1.05);
  }
  40% {
    transform: translate(-2px, 2px) rotate(-15deg) scale(1.05);
    -webkit-transform: translate(-2px, 2px) rotate(-15deg) scale(1.05);
  }
  66% {
    transform: translate(0, -1px) rotate(-13deg) scale(1);
    -webkit-transform: translate(0, -1px) rotate(-13deg) scale(1);
  }
  75% {
    transform: translate(0, 0) rotate(-12deg) scale(1);
    -webkit-transform: translate(0, 0) rotate(-12deg) scale(1);
  }
}

@keyframes float-3 {
  0% {
    transform: translateY(4px) rotateZ(1.25deg);
    -webkit-transform: translateY(4px) rotateZ(1.25deg);
  }
  50% {
    transform: translateY(-4px) rotateZ(2deg);
    -webkit-transform: translateY(-4px) rotateZ(2deg);
  }
  100% {
    transform: translateY(4px) rotateZ(1.25deg);
    -webkit-transform: translateY(4px) rotateZ(1.25deg);
  }
}

@keyframes float-4 {
  0% {
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
  }
  25% {
    transform: translateY(4px);
    -webkit-transform: translateY(4px);
  }
  35% {
    transform: translateY(3px);
    -webkit-transform: translateY(3px);
  }
  50% {
    transform: translateY(8px);
    -webkit-transform: translateY(8px);
  }
  100% {
    transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
  }
}
