@font-face {
  font-family: 'Roboto';
  src: url('Roboto/Roboto-Bold.ttf');
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('Roboto/Roboto-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('Roboto/Roboto-Medium.ttf');
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('Roboto/Roboto-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('Roboto/Roboto-Regular.ttf');
  src: local('Roboto Regular'), local('Roboto-Regular'),
    url('Roboto/Roboto-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('Roboto/Roboto-Light.ttf');
  src: local('Roboto Light'), local('Roboto-Light'),
    url('Roboto/Roboto-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('Roboto/Roboto-Thin.ttf');
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('Roboto/Roboto-Thin.ttf') format('truetype');
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'Caveat';
  src: url('Caveat/Caveat-Regular.ttf');
  src: local('Caveat Regular'), local('Caveat-Regular'),
    url('Caveat/Caveat-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
