@import 'fonts/fonts.scss';
@import 'styles/colors.scss';


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  // -webkit-touch-callout: none;
}

html, body {
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
}

body {
  max-width: 100%;
  color: var(--main-font-color);
  font-size: 23px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -o-font-smoothing: antialiased;
  // text-rendering: geometricPrecision;
}

a {
  text-decoration: none;
  color: #5affff;
  font-weight: 500;
}

img {
  pointer-events: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto';
  font-weight: normal;
}

h1 {
  font-size: 42px;
}

hr {
  border: 1px solid white;
  border-bottom: 0;
  margin-bottom: .5rem;
  background-color: white;
}

.text-sm {
  font-size: 21px;
}

.text-lg {
  font-size: 26px;
}

.text-lt {
  font-weight: 300;
}

.text-md {
  font-weight: 500;
}
